import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, PageIntro, TriageList, PrimaryCallOut, FilterProvider } from '../../components';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Dosing Schedule for KEYTRUDA® (pembrolizumab) | HCP`,
    keywords: `keytruda dosing, pembrolizumab dosing`,
    description: `Health care professionals may find information about dosing for KEYTRUDA® (pembrolizumab). Explore indication-specific dosing information for adult and pediatric patients.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/options/","@type":"MedicalWebPage","name":"Dosing Options for KEYTRUDA® (pembrolizumab)","description":"Choose an appropriate dosing regimen for your patients by selecting an indication below. See full Prescribing Information for preparation and administration instructions, dosage modifications for adverse reactions, and full indication statements for each indication."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/options/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/options/","@type":"Drug","proprietaryName":"KEYTRUDA®","nonProprietaryName":"pembrolizumab","dosageForm":"Injection","administrationRoute":"intravenous infusion","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf","doseSchedule":{"@type":"DoseSchedule","doseUnit":"mg","doseValue":"200","frequency":"every 3 weeks (Q3W)","targetPopulation":"Adult Patients"}}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/options/","@type":"Drug","proprietaryName":"KEYTRUDA®","nonProprietaryName":"pembrolizumab","dosageForm":"Injection","administrationRoute":"intravenous infusion","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf","doseSchedule":{"@type":"DoseSchedule","doseUnit":"mg","doseValue":"400","frequency":"every 6 weeks (Q6W)","targetPopulation":"Adult Patients"}}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/dosing/options/","@type":"Drug","proprietaryName":"KEYTRUDA®","nonProprietaryName":"pembrolizumab","dosageForm":"Injection","administrationRoute":"intravenous infusion","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/k/keytruda/keytruda_pi.pdf","doseSchedule":{"@type":"DoseSchedule","doseUnit":"mg/kg","doseValue":"2","frequency":"every 3 weeks (Q3W)","targetPopulation":"Pediatric Patients"}}`
    ]
}

const jobCode = jobCodes[30].jobCode;

const relatedContent = [
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/preparation-storage-administration/',
            label: 'Preparation, Storage & Administration'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/dose-modifications/',
            label: 'Dose Modifications'
        }
    },
]

const Page = ({ location }) => {
    return (
        <FilterProvider>
            <DefaultLayout jobCode={jobCode} pageMeta={pageMeta} location={location}>

            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro">
                    <PageHeader title="Dosing" />
                    <PageSection title="Dosing Options for KEYTRUDA® (pembrolizumab)" centerTitle={true}>
                        <PageIntro centered={true}>
                            <p>Choose an appropriate dosing regimen for your patients by selecting an indication below. <b>See full Prescribing Information for preparation and administration instructions, dosage modifications for adverse reactions, and full indication statements for each indication.</b></p>
                        </PageIntro>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection title="Indication-Specific Dosing Information" bgColor="cloud">
                        <TriageList listType="dosing"/>
                    </PageSection>
                    <PageSection>
                        <PrimaryCallOut
                            title="Not seeing the type of tumor you are looking for?"
                            content="For more information, including a complete list of indications KEYTRUDA is approved to treat, please see the full Prescribing Information for KEYTRUDA."
                            secondaryAction={{ label: 'View Prescribing Information', href: '/prescribing-information/', external: true, target: '_blank' }}
                        />
                    </PageSection>
                    <PageSection bgColor='keytrudaGreen'>
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    </FilterProvider>
    );
};

export default Page;
